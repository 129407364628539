import { useMemo } from "react";
import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Card,
  Title,
  CardGrid,
  Section,
  Link,
  Button,
  LogoGrid,
  LogoGridLogo,
} from "@lleed-partners/factor";
import {
  ArrowRight32,
  ChatLaunch32,
  Launch32,
  EventsAlt32,
  LogoGithub32,
} from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next, Trans } from "gatsby-plugin-react-i18next";
import { Icon } from "../fragments/Icon";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { ServiceCard, TServiceCardData } from "../fragments/Card/service";
import { asBackground } from "../fragments/styles";
import { Layout } from "../layouts";
import { InsightCard } from "../fragments/Card/insight";
import RioTintoLogo from "../images/customers/rio-tinto.svg";
import LouisDreyfusCompanyLogo from "../images/customers/louis-dreyfus-company.svg";
import { Helmet } from "gatsby-plugin-react-i18next";
import { trail } from "../fragments";

export default ({ data }) => {
  const { t } = useI18next();

  const { services } = useMemo(() => {
    const services = {};

    const subServices = [];

    data.services.edges.forEach(({ node }) => {
      if (node.frontmatter.service) {
        subServices.push(node);
      } else {
        services[node.frontmatter.slug] = { ...node, subServices: [] };
      }
    });

    subServices.forEach((node) => {
      services[node.frontmatter.service].subServices.push(node);
    });

    return {
      services,
    };
  }, [data]);

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content={t("lleedpartners.meta-description")}
        />
        <meta
          property="og:image"
          content="https://images.unsplash.com/photo-1585713181935-d5f622cc2415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2FyZ28lMjBzaGlwfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=1200&q=60"
        />
      </Helmet>
      <LeadSection
        padTop
        title={t("We solve business challenges with technology")}
        subtitle={t(
          "Digital transformation isn't going paperless anymore, it has become our century's business challenge. Here's how you can overcome it."
        )}
        cta={
          <>
            <Button
              intent="primary"
              rightIcon={<Icon icon={<ChatLaunch32 />} />}
              as={GatsbyLink}
              to="/contact/"
            >
              {t("Talk to an advisor")}
            </Button>
            <Button
              intent="ghost"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
              as={GatsbyLink}
              to="/audit/"
            >
              {t("Free digital audit")}
            </Button>
          </>
        }
      />
      <Section
        fullScreen
        intent="grey"
        title={
          <Title
            title={t("Be ahead of your time.")}
            subtitle={t(
              "We leverage state-of-the-art technologies to improve your business."
            )}
          />
        }
      ></Section>
      {/*
      <Section
        fullScreen
        intent="ghostInverted"
        backgroundMedia={
          <StaticImage
            src="https://images.unsplash.com/photo-1570979872224-a1ea9f1248b0"
            alt=""
            style={asBackground}
            loading="eager"
          />
        }
        title={
          <Title
            title={t("Automating metals sales processes:")}
            subtitle={t("reducing friction and increasing efficiency.")}
            cta={
              <Button
                intent="white"
                rightIcon={<Icon icon={<ArrowRight32 />} />}
                as={GatsbyLink}
                to="/brochures/metals-sales/"
              >
                {t("Download the brochure")}
              </Button>
            }
          >
            {t(
              "Discover how we leveraged teams' expertise along with next generation technologies to automate metals sales processes, increasing efficiency and ensuring compliance."
            )}
          </Title>
        }
      />
      */}
      <Section
        fullScreen
        intent="white"
        title={<Title title={t("Our services")} />}
      />
      {Object.keys(services)
        .sort()
        .map((serviceSlug, index) => {
          const service = services[serviceSlug];

          const intent = index % 2 == 0 ? "white" : "grey";

          return (
            <Section
              intent={intent}
              fullScreen
              inline
              key={service.frontmatter.slug}
              title={
                <Title
                  title={service.frontmatter.title}
                  subtitle={service.frontmatter.subtitle}
                  cta={
                    <Button
                      rightIcon={<Icon icon={<ArrowRight32 />} />}
                      as={GatsbyLink}
                      to={trail(service.frontmatter.slug)}
                    >
                      {service.frontmatter.title}
                    </Button>
                  }
                >
                  {service.frontmatter.description}
                </Title>
              }
            >
              <CardGrid intent={intent} bordered>
                {service.subServices.map((subService: TServiceCardData) => {
                  return (
                    <ServiceCard
                      service={subService}
                      key={subService.frontmatter.slug}
                      size="xLarge"
                    />
                  );
                })}
              </CardGrid>
            </Section>
          );
        })}
      <LeadSection
        inline
        backgroundMedia={
          <StaticImage
            src="https://images.unsplash.com/photo-1560264280-88b68371db39"
            alt=""
            style={asBackground}
            loading="lazy"
          />
        }
        title={t("Start your digital transformation now")}
        cta={
          <Button
            intent="primary"
            as={GatsbyLink}
            to="/contact/"
            rightIcon={<Icon icon={<ArrowRight32 />} />}
          >
            Contact us
          </Button>
        }
        subtitle={t(
          "Discover how we can help you take advantage of newer technologies"
        )}
      />
      <Section
        intent="grey"
        inline
        fullScreen
        title={
          <Title inline={true} title={t("High stakes are not an issue.")}>
            {t(
              "We have worked with top companies worldwide to satisfy their most demanding needs."
            )}
          </Title>
        }
      >
        <LogoGrid size="xLarge" intent="grey" bordered>
          <LogoGridLogo>
            <img src={RioTintoLogo} alt="RioTinto Logo" />
          </LogoGridLogo>
        </LogoGrid>
      </Section>
      <Section
        intent="white"
        fullScreen
        title={
          <Title title={t("Case Studies & Insights")}>
            {t(
              "Discover our latest case studies and insights on the business world"
            )}
          </Title>
        }
      >
        <CardGrid intent="white" bordered>
          {data.caseStudiesAndInsights.edges.map(({ node }) => (
            <InsightCard
              insight={node}
              key={node.frontmatter.slug}
              size="xLarge"
            />
          ))}
        </CardGrid>
      </Section>
      {/*
      <Section
        intent="white"
        fullScreen
        title={
          <Title title={t("Meet our team")}>
            {t("Our experts are here for you.")}
          </Title>
        }
      >
        <CardGrid intent="white" bordered>
          {data.members.edges.map(({ node }) => (
            <Card
              size="xLarge"
              icon={
                <div
                  style={{
                    maxWidth: "10rem",
                  }}
                >
                  <GatsbyImage
                    image={node.picture.childImageSharp.gatsbyImageData}
                    alt={node.firstName}
                  />
                </div>
              }
              title={`${node.firstName} ${node.lastName}`}
            >
              {node.bioEn}
            </Card>
          ))}
        </CardGrid>
      </Section>
            */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns:
            "repeat(auto-fit, minmax(min(30rem, 100%), 1fr))",
        }}
      >
        <Card
          size="xLarge"
          intent="primary"
          title={t("We are committed to open source")}
          icon={<Icon icon={<LogoGithub32 />} />}
          cta={
            <>
              <Button
                intent="white"
                trackId="front-page-opensource-card-github"
                rightIcon={<Icon icon={<Launch32 />} />}
                as="a"
                href="https://github.com/Lleed-Partners"
                target="_blank"
              >
                Lleed & Partners GitHub
              </Button>
            </>
          }
        >
          <Trans t={t}>
            We believe that open-sourcing our researches will benefit a greater
            audience. From new business workflows to leading edge artificial
            intelligence technologies, Lleed & Partners makes its tools
            available to build a better future.
          </Trans>
        </Card>
        <Card
          size="xLarge"
          intent="dark"
          title={t("Join us")}
          icon={<Icon icon={<EventsAlt32 />} />}
          cta={
            <>
              <Button
                as="a"
                href="https://www.linkedin.com/company/lleedpartners/jobs/"
                target="_blank"
                intent="white"
                rightIcon={<Icon icon={<Launch32 />} />}
                trackId="front-page-join-us-card-linkedin"
              >
                {t("Open positions")}
              </Button>
            </>
          }
        >
          <Trans t={t}>
            Lleed & Partners is expanding accross the world.
            <br />
            <br />
            We are looking for talents with various profiles, who would like to
            direct their career path. Feeling ambitious? See our current job
            openings on LinkedIn.
          </Trans>
        </Card>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    services: allMdx(
      filter: {
        fields: { langKey: { eq: $language } }
        frontmatter: { category: { eq: "service" }, published: { ne: false } }
      }
      sort: { fields: frontmatter___slug, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            description
            subtitle
            service
            icon
          }
        }
      }
    }

    members: allAuthorsYaml {
      edges {
        node {
          id
          lastName
          joined
          linkedin
          location
          picture {
            childImageSharp {
              gatsbyImageData
            }
          }
          role
          slug
          isMember
          bioEn
          bioFr
          firstName
        }
      }
    }

    caseStudiesAndInsights: allMdx(
      filter: {
        fields: { langKey: { eq: $language } }
        frontmatter: {
          category: { in: ["insight", "case-study"] }
          published: { ne: false }
        }
      }
      sort: {
        fields: [frontmatter___modified, frontmatter___slug]
        order: DESC
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            excerpt
            headerImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            localHeaderImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
